import { Injectable, OptionalDecorator, OnDestroy } from '@angular/core';
import { PokerService, PokerMode } from '../poker.interface.service';
import { SessionService } from '../session.interface.service';
import { UserService } from '../user.interface.service';
import { Subscription } from '../../../../../node_modules/rxjs';
import { Estimation } from '../../model/estimation';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root'
})
export class PokerServiceFirebaseV1 implements PokerService, OnDestroy {
  private subscriptions: Subscription[] = [];
  private currentUser: User;

  public users$;
  public estimations$;
  public mode$;

  constructor(
    private sessionService: SessionService,
    private userService: UserService
  ) {
    this.subscriptions.push(this.userService.currentUser$.subscribe(x => this.currentUser = x));
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public estimate(estimation: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setEstimation({
        estimation: estimation,
        userId: this.currentUser.userId
      } as Estimation).then(resolve).catch(reject);
    });
  }
  public reset(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.clearEstimations().then(resolve).catch(reject);
    });
  }
  public changeDescription(description: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setDescription(description).then(resolve).catch(reject);
    });
  }
  public kick(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setKick(userId).then(resolve).catch(reject);
    });
  }
  public changeMode(mode: PokerMode): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setMode(mode).then(resolve).catch(reject);
    });
  }
  public showElmo(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setElmo(true).then(resolve).catch(reject);
    });
  }
  public showCoffee(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sessionService.setCoffee(true).then(resolve).catch(reject);
    });
  }
}
