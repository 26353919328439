import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from './shared/services/appState.interface.service';
import { Observable, Subscription } from 'rxjs';
import { UserService } from './shared/services/user.interface.service';
import { User } from './shared/model/user';
import { SessionService } from './shared/services/session.interface.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private subscriptions = new Array<Subscription>();

  public appTitle$: Observable<string>;
  public isLoading$: Observable<boolean>;
  public currentUser$: Observable<User>;

  constructor(
    private translate: TranslateService,
    private appState: AppStateService,
    private userService: UserService,
    private sessionService: SessionService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.appTitle$ = this.appState.appTitle$;
    this.isLoading$ = this.appState.isLoading$;
    this.currentUser$ = this.userService.currentUser$;
  }
}
