import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-elmo',
  templateUrl: './elmo.component.html',
  styleUrls: ['./elmo.component.scss']
})
export class ElmoComponent implements AfterViewInit {
  @ViewChild('elmoplayer') elmoplayerRef: ElementRef;

  ngAfterViewInit() {
    this.elmoplayerRef.nativeElement.currentTime = 0;
    this.elmoplayerRef.nativeElement.play();
  }
}
