export class Const {
  static readonly APPNAME = 'AgilePoker';

  static readonly LOCAL = {
    CARDMODE: 'CARDMODE',
    USERNAME: 'USERNAME'
  };

  static readonly DB = {
    VERSION: 'V1',
    PIN: {
      PATH: 'PIN'
    },
    SESSION: {
      PATH: 'SESSIONS',
      CREATED: 'CREATED',
      LASTUPDATED: 'LASTUPDATED',
      STATE: {
        PATH: 'STATE',

        COFFEE: 'COFFEE',
        DESCRIPTION: 'DESCRIPTION',
        ELMO: 'ELMO',
        MODE: 'MODE',
        KICK: 'KICK'
      },
      USERS: {
        PATH: 'USERS',
        ID: 'USERID',
        NAME: 'USERNAME'
      },
      ESTIMATIONS: {
        PATH: 'ESTIMATIONS'
      }
    },
    COLORS: {
      PATH: 'COLORS'
    }
  };

  static readonly ROUTE = {
    JOIN: '',
    POKER: 'play',
    POKER_ID: 'id',
    USERNAME: 'user',
    QUESTION: '?'
  };

  static readonly POKER = {
    MUTE: 'MUTED',
    BREAK: 'BREAK',
    THUMBSUP: 'THUMBSUP',
    THUMBSDOWN: 'THUMBSDOWN'
  };

  static readonly CARDMODES = {
    FIBONACCI: 'FIBONACCI',
    TSHIRT: 'TSHIRT'
  };

  static readonly USER_COLORS = {
    GREEN: { name: 'CLR_GREEN', background: '#4CAF50', font: '#000' },
    AMBER: { name: 'CLR_AMBER', background: '#FFC107', font: '#000' },
    RED: { name: 'CLR_RED', background: '#F44336', font: '#fff' },
    BLUE: { name: 'CLR_BLUE', background: '#2196F3', font: '#fff' },
    BLACK: { name: 'CLR_BLACK', background: '#000', font: '#fff' },
    PINK: { name: 'CLR_PINK', background: '#E91E63', font: '#fff' },
    TEAL: { name: 'CLR_TEAL', background: '#009688', font: '#fff' },
    PURPLE: { name: 'CLR_PURPLE', background: '#9C27B0', font: '#fff' },
    DEEPORANGE: { name: 'CLR_DEEPORANGE', background: '#FF5722', font: '#000' },
    INDIGO: { name: 'CLR_INDIGO', background: '#3F51B5', font: '#fff' },
    DEEPPURPLE: { name: 'CLR_DEEPPURPLE', background: '#673AB7', font: '#fff' }
  };
}
