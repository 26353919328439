import { auth } from 'firebase';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../model/user';
import { CookieService } from 'ngx-cookie';
import { Const } from '../../const';
import { Injectable } from '@angular/core';
import { UserService } from '../user.interface.service';

@Injectable()
export class UserServiceFirebaseV1 implements UserService {
  private currentUserSub: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
  private currentUser: User;

  public currentUser$ = this.currentUserSub.asObservable();

  constructor(private cookieService: CookieService) {
    this.currentUser$.subscribe(x => (this.currentUser = x));

    const storedName = this.cookieService.get(Const.LOCAL.USERNAME);
    if (!!storedName) {
      this.setUsername(storedName);
    }
  }

  public login(usr: User): Promise<void> {
    return this.loginAnonymously();
  }
  public register(usr: User): Promise<void> {
    throw new Error('Method not implemented.');
  }
  public async logout() {
    await new Promise((resolve, reject) => {
      auth()
        .signOut()
        .then(usr => {
          this.currentUser.userId = null;
          this.currentUserSub.next(null);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public setUsername(username: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cookieService.put(Const.LOCAL.USERNAME, username);

      this.currentUser.userName = username;
      this.currentUserSub.next(this.currentUser);
      resolve();
    });
  }

  private loginAnonymously(): Promise<void> {
    return new Promise((resolve, reject) => {
      auth()
        .signInAnonymously()
        .then(usr => {
          this.currentUser.userId = usr.uid;
          this.currentUserSub.next(this.currentUser);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
}
