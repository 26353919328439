import { User } from '../model/user';
import { Observable } from 'rxjs/internal/Observable';
import { Estimation } from '../model/estimation';

export enum PokerMode {
  INVISIBLE = 'INVISIBLE',
  VISIBLE = 'VISIBLE'
}

export abstract class PokerService {
  public abstract users$: Observable<User[]>;
  public abstract estimations$: Observable<Estimation[]>;
  public abstract mode$: Observable<PokerMode>;

  public abstract estimate(estimation: string): Promise<void>;
  public abstract reset(): Promise<void>;
  public abstract changeDescription(description: string): Promise<void>;
  public abstract kick(userId: string): Promise<void>;
  public abstract changeMode(mode: PokerMode): Promise<void>;

  public abstract showElmo(): Promise<void>;
  public abstract showCoffee(): Promise<void>;
}
