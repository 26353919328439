import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || args.length === 0) {
      return '';
    }

    const result = [];
    Object.keys(value).forEach(key => {
      let name = value[key][args[0]];
      if (name.length > 20) {
        name = name.substring(0, 20) + '...';
      }
      result.push(name);
    });
    return result.join(args[1] ? args[1] : ', ');
  }

}
