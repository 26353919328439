import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStateService } from '../appState.interface.service';
import { Const } from '../../const';

@Injectable()
export class AppStateServiceFirebaseV1 implements AppStateService {
  private appTitleSub = new BehaviorSubject<string>(Const.APPNAME);
  private isLoadingSub = new BehaviorSubject<boolean>(false);

  public isLoading$: Observable<boolean> = this.isLoadingSub.asObservable();
  public appTitle$: Observable<string> = this.appTitleSub.asObservable();

  constructor() {}

  public setAppTitle(appTitle: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.appTitleSub.next(appTitle);
      resolve();
    });
  }

  public setIsLoading(isLoading: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.isLoadingSub.next(isLoading);
        resolve();
      });
    });
  }

}
