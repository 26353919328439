import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

export enum HelpState {
  MANUAL,
  FAQ,
  WHATSNEW
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  public statesAvailable = HelpState;

  @Input() state: HelpState = HelpState.MANUAL;
  @Output() close = new EventEmitter<void>();

  public closeHelp() { this.close.emit(); }
}
