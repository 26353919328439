import { Observable } from 'rxjs';
import { SessionDto } from '../dataObjects/sessionDto';
import { PokerMode } from './poker.interface.service';
import { Estimation } from '../model/estimation';
import { EstimationDto } from '../dataObjects/estimationDto';
import { UserDto } from '../dataObjects/userDto';
import { SessionStateDto } from '../dataObjects/sessionStateDto';

export abstract class SessionService {
  public abstract sessionStateDto$: Observable<SessionStateDto>;
  public abstract usersDto$: Observable<UserDto>;
  public abstract estimationsDto$: Observable<EstimationDto>;
  public abstract sessionName$: Observable<string>;
  public abstract sessionName: string;

  public abstract createSession(cardMode: string): Promise<void>;
  public abstract checkSession(sessionName: string): Promise<boolean>;

  public abstract joinSession(): Promise<void>;
  public abstract leaveSession(): void;

  public abstract setMode(mode: PokerMode): Promise<void>;
  public abstract setDescription(description: string): Promise<void>;
  public abstract setElmo(show: boolean): Promise<void>;
  public abstract setCoffee(show: boolean): Promise<void>;
  public abstract setEstimation(estimation: Estimation): Promise<void>;
  public abstract setKick(userId: string): Promise<void>;

  public abstract clearEstimations(): Promise<void>;
}
