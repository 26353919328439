import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pokercard',
  templateUrl: './pokercard.component.html',
  styleUrls: ['./pokercard.component.scss']
})
export class PokercardComponent implements OnInit {

  @Input() value;
  @Input() faIcon;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
