import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Const } from '../../shared/const';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { User } from '../../shared/model/user';
import { UserService } from '../../shared/services/user.interface.service';

export interface NameComponentResult {
  name: string;
  cardMode: string;
}

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private currentUser: User;

  public cardModes = Const.CARDMODES;
  public nameForm: FormGroup;
  public result: NameComponentResult = {
    name: '',
    cardMode: this.cardModes.FIBONACCI
  };

  @Input() isNewSession = false;

  @Output() complete = new EventEmitter<{ name: string, cardMode: string }>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private userService: UserService
  ) {
    this.subscriptions.push(userService.currentUser$.subscribe(x => this.currentUser = x));
  }

  ngOnInit() {
    this.nameForm = new FormGroup({
      name: new FormControl(this.currentUser ? this.currentUser.userName : '', [Validators.required])
    });
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  setCardMode(mode: string): void {
    this.result.cardMode = mode;
  }

  submitForm(): void {
    this.result.name = this.nameForm.getRawValue().name;
    this.complete.emit(this.result);
  }

  cancelForm(): void {
    this.cancel.emit();
  }

}
