import { Observable } from 'rxjs';
import { User } from '../model/user';

export abstract class UserService {
  public abstract currentUser$: Observable<User>;

  public abstract login(usr: User): Promise<void>;
  public abstract logout(): void;
  public abstract register(usr: User): Promise<void>;

  public abstract setUsername(username: string): Promise<void>;
}
