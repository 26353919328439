import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appScrollHorizontal]'
})
export class ScrollHorizontalDirective {

  @HostListener('mousewheel', ['$event'])
  @HostListener('DOMMouseScroll', ['$event'])
  @HostListener('onmousewheel', ['$event'])
  scrollEvent(e: MouseWheelEvent) {
    const delta = Math.max(-1, Math.min(1, (e. wheelDelta || -e.detail)));
    this.elementRef.nativeElement.scrollLeft -= (delta * 40);
    e.preventDefault();
  }

  constructor(
    private elementRef: ElementRef
  ) {}

}
