import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JoinComponent } from './join/route/join.component';
import { PokerComponent } from './poker/route/poker.component';
import { Const } from './shared/const';
import { HelpComponent } from './poker/help/help.component';

const routes: Routes = [
  {
    path: 'help',
    component: HelpComponent,
    pathMatch: 'full'
  },
  {
    path: Const.ROUTE.JOIN,
    component: JoinComponent,
    pathMatch: 'full'
  },
  {
    path: ':' + Const.ROUTE.POKER_ID,
    component: JoinComponent,
    pathMatch: 'full'
  },
  {
    path: Const.ROUTE.POKER + '/:' + Const.ROUTE.POKER_ID,
    component: PokerComponent
  },
  {
    path: '**',
    redirectTo: '/' + Const.ROUTE.JOIN,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
