import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase';
import { environment } from '../../../../environments/environment';
import { InitializationService } from '../initialization.interface.service';
import { SessionService } from '../session.interface.service';
import { UserService } from '../user.interface.service';

@Injectable()
export class InitializationServiceFirebaseV1 implements InitializationService {
  constructor(
    private userService: UserService,
    private sessionService: SessionService
  ) { }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      initializeApp(environment.firebase);

      this.userService
        .login(null)
        .then(() => {
          return this.initializeDBColors().then(resolve).catch(reject);
        })
        .catch(() => {
          reject();
        });
    });
  }

  private initializeDBColors(): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
}
