import { Pipe, PipeTransform } from '@angular/core';
import { Const } from '../const';

@Pipe({
  name: 'filterNoVote'
})
export class FilterNoVotePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const cache = value;

    Object.keys(cache).forEach(key => {
      cache[key] = cache[key].filter(a => a.estimation !== Const.POKER.MUTE);
      if (cache[key] == null || cache[key].length === 0) {
        delete cache[key];
      }
    });

    return Object.keys(cache);
  }

}
