import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent {
  public sessionNameArray: string[] = [];

  @Output() complete = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();

  public add(char: string): void {
    this.sessionNameArray.push(char);
    if (this.sessionNameArray.length === 5) {
      this.complete.emit(this.sessionNameArray.join(''));
    }
  }

  public removeOne(): void {
    this.sessionNameArray.pop();
  }

  public clear(): void {
    this.cancel.emit();
  }

  @HostListener('document:keydown', ['$event'])
  private handleKey(event: KeyboardEvent): void {
    switch (event.keyCode) {
      case 48:
      case 96:
        this.add('0');
        break;
      case 49:
      case 97:
        this.add('1');
        break;
      case 50:
      case 98:
        this.add('2');
        break;
      case 51:
      case 99:
        this.add('3');
        break;
      case 52:
      case 100:
        this.add('4');
        break;
      case 53:
      case 101:
        this.add('5');
        break;
      case 54:
      case 102:
        this.add('6');
        break;
      case 55:
      case 103:
        this.add('7');
        break;
      case 56:
      case 104:
        this.add('8');
        break;
      case 57:
      case 105:
        this.add('9');
        break;
      case 8:
      case 46:
        this.removeOne();
        break;
      case 27:
        this.clear();
        break;
    }
  }
}
