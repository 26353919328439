import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JoinComponent } from './join/route/join.component';
import { PokerComponent } from './poker/route/poker.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeysPipe } from './shared/pipes/keys.pipe';
import { SecondsFormatPipe } from './shared/pipes/seconds-format.pipe';
import { AppStateService } from './shared/services/appState.interface.service';
import { JoinPipe } from './shared/pipes/join.pipe';
import { FilterNoVotePipe } from './shared/pipes/filter-no-vote.pipe';
import { CookieModule, CookieService } from 'ngx-cookie';
import { ClipboardModule } from 'ngx-clipboard';
import { ScrollHorizontalDirective } from './shared/directives/scroll-horizontal.directive';
import { AppStateServiceFirebaseV1 } from './shared/services/firebase-v1/appState-fbv1.service';
import { PokercardComponent } from './shared/components/pokercard/pokercard.component';
import { EstimateComponent } from './poker/estimate/estimate.component';
import { ResultsComponent } from './poker/results/results.component';
import { NameComponent } from './join/name/name.component';
import { NumpadComponent } from './join/numpad/numpad.component';
import { InitializationServiceFirebaseV1 } from './shared/services/firebase-v1/initialization-fbv1.service';
import { UserService } from './shared/services/user.interface.service';
import { UserServiceFirebaseV1 } from './shared/services/firebase-v1/user-fbv1.service';
import { InitializationService } from './shared/services/initialization.interface.service';
import { SessionService } from './shared/services/session.interface.service';
import { SessionServiceFirebaseV1 } from './shared/services/firebase-v1/session-fbv1.service';
import { HelpComponent } from './poker/help/help.component';
import { ElmoComponent } from './poker/elmo/elmo.component';
import { CoffeeComponent } from './poker/coffee/coffee.component';
import { FaqComponent } from './poker/help/faq/faq.component';
import { WhatsnewComponent } from './poker/help/whatsnew/whatsnew.component';
import { ManualComponent } from './poker/help/manual/manual.component';
import { PokerService } from './shared/services/poker.interface.service';
import { PokerServiceFirebaseV1 } from './shared/services/firebase-v1/poker-fbv1.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function init_app(initService: InitializationService) {
  return () => initService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    JoinComponent,
    PokerComponent,
    KeysPipe,
    SecondsFormatPipe,
    JoinPipe,
    FilterNoVotePipe,
    ScrollHorizontalDirective,
    PokercardComponent,
    EstimateComponent,
    ResultsComponent,
    NameComponent,
    NumpadComponent,
    HelpComponent,
    ElmoComponent,
    CoffeeComponent,
    FaqComponent,
    WhatsnewComponent,
    ManualComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CookieService,

    { provide: APP_INITIALIZER, useFactory: init_app, deps: [InitializationService], multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },

    { provide: InitializationService, useClass: InitializationServiceFirebaseV1 },
    { provide: AppStateService, useClass: AppStateServiceFirebaseV1 },
    { provide: UserService, useClass: UserServiceFirebaseV1 },
    { provide: PokerService, useClass: PokerServiceFirebaseV1 },
    { provide: SessionService, useClass: SessionServiceFirebaseV1 },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
