import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AppStateService } from '../../shared/services/appState.interface.service';
import { HelpState } from '../help/help.component';
import { PokerService, PokerMode } from '../../shared/services/poker.interface.service';
import { Estimation } from '../../shared/model/estimation';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { Subscription } from '../../../../node_modules/rxjs';
import { SessionService } from '../../shared/services/session.interface.service';

enum PokerState {
  INIT,
  ESTIMATE,
  RESULTS,
  ELMO,
  COFFEE,
  HELP
}

@Component({
  selector: 'app-poker',
  templateUrl: './poker.component.html',
  styleUrls: ['./poker.component.scss']
})
export class PokerComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();

  public HelpState = HelpState;
  public PokerState = PokerState;

  public state: PokerState = PokerState.INIT;

  constructor(
    private appState: AppStateService,
    private pokerService: PokerService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        if (params) {
          const sessionId = params.id;
          if (!Number.isNaN(sessionId) && sessionId.length === 5) {
            this.sessionService
              .checkSession(sessionId)
              .then(result => {
                if (result === true) {
                  this.sessionService
                    .joinSession()
                    .then(() => {
                      this.appState.setIsLoading(false);
                    })
                    .catch(() => {
                      alert('error joining!');
                    });
                } else {
                  alert('not found!');
                }
              })
              .catch(() => {
                alert('error checking!');
              });
          } else {
            alert('invalid code');
          }
        }
      })
    );
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.sessionService.leaveSession();
  }

  public back() {
    this.router.navigate(['/']);
  }

  public estimate() {
    this.pokerService.estimate('13');
  }
  public clear() {
    this.pokerService.reset();
  }
  public desc() {
    this.pokerService.changeDescription('test desc');
  }
  public kick() {
    this.pokerService.kick('kickuser');
  }
  public changeMode() {
    this.pokerService.changeMode(PokerMode.VISIBLE);
  }
  public showElmo() {
    this.pokerService.showElmo();
  }
  public showCoffee() {
    this.pokerService.showCoffee();
  }
}
/*
export class PokerComponent implements OnInit, OnDestroy {

  @ViewChild('audioplayer') audioPlayerRef: ElementRef;

  private FIBONACCI = [Const.POKER_QUESTION, 0, 1, 2, 3, 5, 8, 13, 20, 40, 99].map(String);
  private TSHIRT = [Const.POKER_QUESTION, 'XS', 'S', 'M', 'L', 'XL'].map(String);

  public estimations;
  public const = Const;

  private URLREGEX = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  public descriptionUrl = '';

  public infoTabs = infoTabs;

  public status: {
    loaded: boolean;
    alreadyLoggedIn: boolean;
    showInfo: boolean;
    infoTab: infoTabs;
    usernameEdit: boolean;
  } = {
    loaded: false,
    alreadyLoggedIn: false,
    showInfo: false,
    infoTab: infoTabs.HELP,
    usernameEdit: false
  };

  private lastUpdated = 'date2018-06-19v1';
  public hasSeenNews = false;

  public copied = false;
  public copiedTimer: any;

  private authSub: Subscription;
  private paramSub: Subscription;

  private usersSub: Subscription;
  private usersRef: AngularFireObject<{ [id: string]: User }>;
  public user = new User(null, null, null, null);

  private stateSub: Subscription;
  private stateRef: AngularFireObject<SessionState>;

  public session = new Session();
  public roomUrl: string;

  constructor(
    private route: ActivatedRoute,
    private fbDb: AngularFireDatabase,
    private fbAuth: AngularFireAuth,
    private appState: AppStateService,
    private cookieService: CookieService,
    private router: Router,
    public translate: TranslateService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
      this.session.sessionName = params[Const.ROUTE_POKER_ID];
      document.title = Const.APPNAME + '#' + this.session.sessionName;
      this.roomUrl = window.location.href;
      this.user.userName = this.cookieService.get(Const.ROUTE_USERNAME);

      const lastupdatedcookie = this.cookieService.get('whatsnew_' + this.lastUpdated);
      if (lastupdatedcookie == null || lastupdatedcookie.toLowerCase() === 'false') {
        this.status.infoTab = this.infoTabs.NEW;
      } else {
        this.hasSeenNews = true;
      }

      if (this.user.userName == null || this.user.userName === '') {
        this.router.navigateByUrl('/' + this.session.sessionName);
      } else {
        this.fbAuth.authState.subscribe(auth => {
          this.user.userId = auth.uid;
          this.startPoker();
        });
      }
    });
  }

  ngOnDestroy() {
    if (!!this.status.loaded && !this.status.alreadyLoggedIn) {
      this.stateSub.unsubscribe();
      this.usersSub.unsubscribe();
      this.fbDb.database
        .ref(Const.DB_VERSION + '/' + Const.DB_SESSION + '/' + this.session.sessionName + '/' + Const.DB_USERS)
        .child(this.user.userId)
        .remove();
    }
  }

  closeInfoWindow() {
    this.status.showInfo = false;
    this.status.infoTab = this.infoTabs.HELP;
    this.hasSeenNews = true;
    this.cookieService.put('whatsnew_' + this.lastUpdated, 'true');
  }

  startPoker() {
    this.appState.setAppTitle(this.session.sessionName);
    this.usersRef = this.fbDb.object(
      Const.DB_VERSION + '/' + Const.DB_SESSION + '/' + this.session.sessionName + '/' + Const.DB_USERS
    );

    // subscribe on session users
    this.usersSub = this.usersRef.valueChanges().subscribe(users => {
      console.log('userRef val changed');

      if (users && !this.user.color) {
        this.findUserColor(users);
      }
      if (!!this.status.loaded) {
        // just a simple update
        this.session.users = users;
        if (this.session.users[this.user.userId]) {
          this.user = this.session.users[this.user.userId];
        } else {
          this.updateUser();
        }
      } else {
        // initializing:
        if (users && users[this.user.userId]) {
          // user is already logged in
          this.status.loaded = true;
          this.status.alreadyLoggedIn = true;
        } else {
          // normal startup
          this.updateUser();

          this.fbDb.database
            .ref(Const.DB_VERSION + '/' + Const.DB_SESSION + '/' + this.session.sessionName + '/' + Const.DB_USERS)
            .child(this.user.userId)
            .onDisconnect()
            .remove();
        }
      }
      if (this.session.sessionState) {
        this.status.loaded = true;
      }
      this.calculateResults();
    });

    // subscribe on session state
    this.stateRef = this.fbDb.object(
      Const.DB_VERSION + '/' + Const.DB_SESSION + '/' + this.session.sessionName + '/' + Const.DB_STATE
    );
    this.stateSub = this.stateRef.valueChanges().subscribe(state => {
      console.log('stateRef val changed');
      if (state == null) {
        this.session.sessionState = new SessionState();
        const cardMode = window.sessionStorage.getItem(Const.CARDMODE);
        if (!!cardMode) {
          this.session.sessionState.cardMode = cardMode;
        }
        this.stateRef.update(this.session.sessionState);
      } else {
        this.session.sessionState = state;

        switch (state.cardMode) {
          case Const.CARDMODES.TSHIRT:
            this.estimations = this.TSHIRT;
            break;
          default:
            this.estimations = this.FIBONACCI;
            break;
        }
        this.descriptionUrl = (this.session.sessionState.description.match(this.URLREGEX) || [
          ''
        ])[0];
        if (
          this.descriptionUrl.length > 0 &&
          this.descriptionUrl.toLowerCase().indexOf('http://') === -1
        ) {
          this.descriptionUrl = 'http://' + this.descriptionUrl;
        }
      }
      if (this.session.users && this.session.users[this.user.userId]) {
        this.status.loaded = true;
      }

      if (
        this.status.loaded &&
        this.session.sessionState.kickUser !== '' &&
        this.session.sessionState.kickUser === this.user.userId
      ) {
        this.deleteOwnUser();
      }
    });
  }

  setEstimation(estimation) {
    this.audioPlayerRef.nativeElement.play();
    if (this.user.estimation === estimation) {
      this.user.estimation = null;
    } else {
      this.user.estimation = estimation;
    }

    this.updateUser();
  }

  clearEstimations() {
    this.user.estimation = this.user.estimation === Const.POKER_MUTE ? Const.POKER_MUTE : null;

    for (const key of Object.keys(this.session.users)) {
      this.session.users[key].estimation =
        this.session.users[key].estimation === Const.POKER_MUTE ? Const.POKER_MUTE : null;
    }
    this.usersRef.update(this.session.users);
    if (this.session.sessionState.showEstimations === true) {
      this.toggleEstimations();
    }

    this.updateState();
  }

  showElmo() {
    this.session.sessionState.showElmo = true;
    this.updateState();
  }

  showCoffee() {
    this.session.sessionState.showCoffee = true;
    this.updateState();
  }

  toggleEstimations() {
    this.session.sessionState.showEstimations = !this.session.sessionState.showEstimations;
    this.updateState();
  }

  updateUser() {
    if (this.user.userName !== '') {
      this.usersRef.update({ [this.user.userId]: this.user });
      this.updateState();
    } else {
      console.log('username is empty - wont update');
    }
  }

  updateState() {
    if (this.stateRef && this.session.sessionState) {
      this.session.sessionState.lastUpdated = new Date().getTime();
      this.stateRef.update(this.session.sessionState);
    }
  }

  clearDescription() {
    this.session.sessionState.description = '';
    this.updateState();
  }

  calculateResults() {
    this.session.results = {};
    const usr = this.session.users;
    if (usr != null) {
      for (const key of Object.keys(this.session.users)) {
        const current = this.session.users[key];
        const est = current.estimation ? current.estimation : null;

        if (est != null && [Const.POKER_MUTE].indexOf(est) === -1) {
          if (!this.session.results) {
            this.session.results = {};
          }
          if (this.session.results[est]) {
            this.session.results[est].push(current);
          } else {
            this.session.results[est] = [current];
          }
        }
      }
    }
    this.calculateFacts();
  }

  calculateFacts() {
    this.session.facts = {};

    let min: number = null;
    let max: number = null;
    let amount: number = null;
    let sum: number = null;

    for (const x of Object.keys(this.session.results)) {
      amount = amount === null ? 0 : amount;
      sum = sum === null ? 0 : sum;

      const est = Number(x);
      if (!isNaN(est)) {
        if (min === null || est < min) {
          min = est;
        }
        if (max === null || est > max) {
          max = est;
        }
        const a: number = this.session.results[est].length;
        amount += a;
        sum = sum + est * a;
      }
      this.session.facts.avg = sum !== null && amount !== null ? sum / amount : null;
      this.session.facts.range = max !== null && min !== null ? max - min : null;
    }
  }

  showCopied() {
    if (this.copiedTimer) {
      clearTimeout(this.copiedTimer);
    }
    this.copied = true;
    this.copiedTimer = setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  toggleUsernameInput() {
    if (this.user.userName !== '') {
      this.status.usernameEdit = !this.status.usernameEdit;
      setTimeout(() => {
        const usrinp = this.renderer.selectRootElement('#usernameInput');
        if (this.status.usernameEdit === true) {
          usrinp.focus();
        } else {
          usrinp.blur();
          this.updateUser();
        }
      });
    }
  }

  kickUser(toBeKicked: string) {
    if (window.confirm('Do you really want to kick him?')) {
      this.session.sessionState.kickUser = toBeKicked;
      this.updateState();
    }
  }

  deleteOwnUser() {
    this.router.navigate(['/']);
    alert('You were kicked from the session.');
  }

  findUserColor(users: { [id: string]: User }) {
    let color: Color = new Color('random', '#ff00ff', '#fff');
    if (users) {
      const usersString = JSON.stringify(users);
      for (const key of Object.keys(Const.USER_COLORS)) {
        if (
          usersString.indexOf(Const.USER_COLORS[key].name) === -1 &&
          usersString.indexOf(Const.USER_COLORS[key].background) === -1
        ) {
          color = new Color(
            Const.USER_COLORS[key].name,
            Const.USER_COLORS[key].background,
            Const.USER_COLORS[key].font
          );
          break;
        }
      }
    }
    this.user.color = color;
    this.updateUser();
  }
}
  */
