import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsFormat'
})
export class SecondsFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = '';
    if (value) {
      const minutes = Math.floor(value / 60);
      const seconds = Math.floor(value - minutes * 60);

      result = (minutes + '').padStart(2, '0') + ':' + (seconds + '').padStart(2, '0');
    }
    return result;
  }

}
