import { Pipe, PipeTransform } from '@angular/core';
import { Const } from '../const';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  transform(value: any, args: any[] = null): any {
    let keys = null;

    if (value) {
      keys = Object.keys(value);
      keys = keys.filter(key => value[key] && value[key].estimation !== Const.POKER.MUTE);
    }

    return keys;
  }

}
