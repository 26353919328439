import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Const } from '../../shared/const';
import { SessionService } from '../../shared/services/session.interface.service';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../shared/services/appState.interface.service';
import { NameComponentResult } from '../name/name.component';
import { UserService } from '../../shared/services/user.interface.service';

enum JoinStates {
  INIT,
  JOIN,
  NAMENEW,
  NAME
}

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit, OnDestroy {
  public statesAvailable = JoinStates;
  public state: JoinStates = JoinStates.INIT;

  private subscriptions = new Array<Subscription>();
  private sessionname: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private sessionService: SessionService,
    private appState: AppStateService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(sessionService.sessionName$.subscribe(x => this.sessionname = x));
    this.subscriptions.push(route.params.subscribe(params => {

      if (params) {
        const sessionId = Number(params.id);
        if (!Number.isNaN(sessionId) && sessionId.toString().length === 5) {
          this.completeJoin(params.id);
        }
      }
    }));
  }

  ngOnInit() {
    document.title = Const.APPNAME;
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  public newSession() {
    this.state = JoinStates.NAMENEW;
  }

  public joinSession() {
    this.state = JoinStates.JOIN;
  }

  public completeJoin(sessionname: string) {
    this.appState.setIsLoading(true);
    this.sessionService
      .checkSession(sessionname)
      .then(isSessionExisting => {
        if (!isSessionExisting) {
          alert('Session not found!');
          this.state = JoinStates.INIT;
        } else {
          this.state = JoinStates.NAME;
        }
        this.appState.setIsLoading(false);
      })
      .catch(() => {
        alert('An error occured.');
        this.appState.setIsLoading(false);
      });
  }

  public completeName(result: NameComponentResult) {
    this.appState.setIsLoading(true);

    const promises: Promise<string>[] = [];
    if (this.state === JoinStates.NAMENEW) {
      this.sessionService
        .createSession(result.cardMode)
        .then((sessionname) => {
            this.userService.setUsername(result.name).then(() => {
              this.router.navigate(['/' + Const.ROUTE.POKER + '/' + this.sessionname]);
            });
        }).catch(() => this.appState.setIsLoading(false));
    } else {
      this.userService.setUsername(result.name).then(() => {
        this.router.navigate(['/' + Const.ROUTE.POKER + '/' + this.sessionname]);
      });
    }
  }

  public cancel() {
    this.state = JoinStates.INIT;
  }
}
