import { Injectable, OnDestroy, HostListener } from '@angular/core';
import { SessionService } from '../session.interface.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Const } from '../../const';
import { database } from 'firebase';
import { SessionStateDto } from '../../dataObjects/sessionStateDto';
import { SessionDto } from '../../dataObjects/sessionDto';
import { UserDto } from '../../dataObjects/userDto';
import { EstimationDto } from '../../dataObjects/estimationDto';
import { PokerMode } from '../poker.interface.service';
import { Estimation } from '../../model/estimation';
import { UserService } from '../user.interface.service';
import { User } from '../../model/user';

@Injectable({
  providedIn: 'root'
})
export class SessionServiceFirebaseV1 implements SessionService, OnDestroy {
  private subscriptions: Subscription[] = [];
  private currentUser: User;

  public sessionName: string;
  private sessionNameSub = new BehaviorSubject<string>(null);
  public sessionName$: Observable<string> = this.sessionNameSub.asObservable();

  private sessionStateDtoRef: any;
  private sessionStateDtoSub = new BehaviorSubject<SessionStateDto>(null);
  public sessionStateDto$: Observable<SessionStateDto> = this.sessionStateDtoSub.asObservable();

  private usersDtoRef: any;
  private usersDtoSub = new BehaviorSubject<UserDto>(null);
  public usersDto$: Observable<UserDto> = this.usersDtoSub.asObservable();

  private estimationsDtoRef: any;
  private estimationsDtoSub = new BehaviorSubject<EstimationDto>(null);
  public estimationsDto$: Observable<EstimationDto> = this.estimationsDtoSub.asObservable();

  constructor(private userService: UserService) {
    this.subscriptions.push(
      this.sessionNameSub.subscribe(x => {
        this.sessionName = x;
      })
    );
    this.subscriptions.push(
      this.userService.currentUser$.subscribe(x => {
        this.currentUser = x;
      })
    );
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.leaveSession();
  }

  public checkSession(sessionName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      database()
        .ref(Const.DB.VERSION + '/' + Const.DB.SESSION.PATH + '/' + sessionName)
        .once('value')
        .then(snapshot => {
          const result = snapshot.val();
          if (!!result) {
            this.sessionNameSub.next(sessionName);
          }
          resolve(!!result);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public createSession(cardMode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(Const.DB.VERSION + '/' + Const.DB.PIN.PATH)
        .transaction((current: number) => {
          return current == null || current === 99999 ? 0 : current + 1;
        })
        .then(result => {
          const newPin = Number(result.snapshot.val())
            .toString()
            .padStart(5, '0');
          this.sessionNameSub.next(newPin);
          this.createSessionObjectInFirebase(cardMode)
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public joinSession(): Promise<void> {
    return new Promise((resolve, reject) => {
      const promises: Promise<any>[] = [];
      this.sessionStateDtoRef = database().ref(
        Const.DB.VERSION +
          '/' +
          Const.DB.SESSION.PATH +
          '/' +
          this.sessionName +
          '/' +
          Const.DB.SESSION.STATE.PATH
      );
      promises.push(
        this.sessionStateDtoRef.on('value', snapshot => {
          const newValue = snapshot.val() as SessionStateDto;
          console.log(newValue);
          this.sessionStateDtoSub.next(newValue);
        })
      );

      this.usersDtoRef = database().ref(
        Const.DB.VERSION +
          '/' +
          Const.DB.SESSION.PATH +
          '/' +
          this.sessionName +
          '/' +
          Const.DB.SESSION.USERS.PATH
      );
      promises.push(
        this.usersDtoRef.on('value', snapshot => {
          const newValue = snapshot.val();
          console.log(newValue);
        })
      );

      this.estimationsDtoRef = database().ref(
        Const.DB.VERSION +
          '/' +
          Const.DB.SESSION.PATH +
          '/' +
          this.sessionName +
          '/' +
          Const.DB.SESSION.ESTIMATIONS.PATH
      );
      promises.push(
        this.estimationsDtoRef.on('value', snapshot => {
          const newValue = snapshot.val();
          console.log(newValue);
        })
      );

      promises.push(
        database()
          .ref(
            Const.DB.VERSION +
              '/' +
              Const.DB.SESSION.PATH +
              '/' +
              this.sessionName +
              '/' +
              Const.DB.SESSION.USERS.PATH +
              '/' +
              this.currentUser.userId
          )
          .set(this.currentUser)
      );

      Promise.all(promises)
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  public leaveSession() {
    this.cleanFirebase();
  }

  public setMode(mode: PokerMode): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.STATE.PATH +
            '/' +
            Const.DB.SESSION.STATE.MODE
        )
        .set(mode)
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public setDescription(description: string): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.STATE.PATH +
            '/' +
            Const.DB.SESSION.STATE.DESCRIPTION
        )
        .set(description)
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public setElmo(show: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.STATE.PATH +
            '/' +
            Const.DB.SESSION.STATE.ELMO
        )
        .set(show)
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public setEstimation(estimation: Estimation): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.ESTIMATIONS.PATH +
            '/' +
            estimation.estimation +
            '/' +
            estimation.userId
        )
        .set(Date.now())
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public setCoffee(show: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.STATE.PATH +
            '/' +
            Const.DB.SESSION.STATE.COFFEE
        )
        .set(show)
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  public clearEstimations(): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.ESTIMATIONS.PATH
        )
        .remove()
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(reject);
    });
  }

  public setKick(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      database()
        .ref(
          Const.DB.VERSION +
            '/' +
            Const.DB.SESSION.PATH +
            '/' +
            this.sessionName +
            '/' +
            Const.DB.SESSION.STATE.PATH +
            '/' +
            Const.DB.SESSION.STATE.KICK
        )
        .set(userId)
        .then(() => {
          this.setLastUpdated();
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  private createSessionObjectInFirebase(cardMode: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const sessionState = {
        MODE: cardMode,
        DESCRIPTION: 'Welcome to #' + this.sessionName + '!',
        COFFEE: false,
        ELMO: false,
        KICK: ''
      } as SessionStateDto;

      const session = {
        STATE: sessionState,
        LASTUPDATED: Date.now(),
        CREATED: Date.now()
      } as SessionDto;

      database()
        .ref(Const.DB.VERSION + '/' + Const.DB.SESSION.PATH + '/' + this.sessionName)
        .set(session)
        .then(resolve)
        .catch(err => reject(err));
    });
  }

  private setLastUpdated() {
    database()
      .ref(
        Const.DB.VERSION +
          '/' +
          Const.DB.SESSION.PATH +
          '/' +
          this.sessionName +
          '/' +
          Const.DB.SESSION.LASTUPDATED
      )
      .set(Date.now());
  }

  private async cleanFirebase() {
    if (this.usersDtoRef) {
      this.usersDtoRef.off('value');
      this.usersDtoRef = null;
    }
    if (this.sessionStateDtoRef) {
      this.sessionStateDtoRef.off('value');
      this.sessionStateDtoRef = null;
    }
    if (this.estimationsDtoRef) {
      this.estimationsDtoRef.off('value');
      this.estimationsDtoRef = null;
    }
    await database()
      .ref(
        Const.DB.VERSION +
          '/' +
          Const.DB.SESSION.PATH +
          '/' +
          this.sessionName +
          '/' +
          Const.DB.SESSION.USERS.PATH +
          '/' +
          this.currentUser.userId
      )
      .set(null);
    this.sessionNameSub.next(null);
  }
}
